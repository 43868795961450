<template>
  <div>
    <b-tabs>
      <b-tab>
        <template #title>
          <icon icon="clone"/>
          <span class="ml-50">{{ capitalize($tc('template', 2)) }}</span>
        </template>

        <templates />
      </b-tab>
      <b-tab disabled>
        <template #title>
          <icon icon="pause"/>
          <span class="ml-50">Pipeline</span>
        </template>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <icon icon="copy"/>
          <span class="ml-50">Documents</span>
        </template>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <icon icon="chart-line"/>
          <span class="ml-50">Indicateurs</span>
        </template>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'
import Templates                from './templates/Templates.vue'
import useAPI                   from '../../utils/useAPI'

export default {
  components: { Templates },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteEmployees,
      fetchAutocompleteWorkflows,
      fetchAutocompleteWorkFlos,
    } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchAutocompleteEmployees()
    fetchAutocompleteWorkflows()
    fetchAutocompleteWorkFlos()

    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>