import store from '@/store'
import { computed } from '@vue/composition-api'
import { clone } from '../../../utils/utils'

export const useTemplates = () => {

  const submitValidatedTemplate = (template) => {
    return new Promise((resolve, reject) => {
      if ('id' in template) {
        updateTemplate(template)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewTemplate(template)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewTemplate = (template) => {
    return new Promise((resolve, reject) => {
      store.dispatch('template/addTemplate', template)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateTemplate = (template) => {
    return new Promise((resolve, reject) => {
      store.dispatch('template/updateTemplate', template)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeTemplate = (template) => {
    return new Promise((resolve, reject) => {
      store.dispatch('template/removeTemplate', template)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedTemplate,
    removeTemplate,
  }
}