<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('template',2)"
        :items="templates"
        :items-context="templatesContext"
        :available-filters="store.getters['template/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="false"

        @addItem="templateModalShow=true"
    >
      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editTemplateLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Duplicate -->
        <duplicate-button
            @click.native.stop="duplicateTemplateLocal(item)"
            v-b-tooltip.hover.left="$t('Duplicate')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteTemplateAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :is-disabled="item._createdBy.id != getUserData().id"
            size="sm"
        />

      </template>
    </base-template>

    <!-- modal template -->
    <template-modal
        :template.sync="currentTemplate"
        :title="('id' in currentTemplate)?$t('EditTemplate'):$t('NewTemplate')"
        :is-open.sync="templateModalShow"
        @submitValidated="submitValidatedTemplateLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch }                      from '@vue/composition-api'
import { capitalize }                                       from '../../utils/filter'
import { clone, downloadByDocument, getRoute, getUserData } from '../../utils/utils'
import useAPI                                               from '../../utils/useAPI'
import store                                         from '../../store'
import moment                                        from 'moment'
import BaseTemplate                                  from '../base3/Base.vue'
import ButtonRemove                                  from '../button/Remove.vue'
import ButtonEdit                                    from '../button/Edit.vue'
import DuplicateButton                               from '../button/Duplicate.vue'
import i18n                                          from '../../libs/i18n'
import { useTemplates }                              from '../../views/parameters/templates/useTemplates'
import TemplateModal                                 from '../prompt/TemplateModal.vue'

export default {
  components: {
    TemplateModal,
    ButtonEdit,
    ButtonRemove,
    DuplicateButton,
    BaseTemplate,
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('templates')
    const payload = ref({})
    const isReady = ref(false)

    const currentTemplate = ref(null)
    const templateModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { templates, templatesContext, autocompleteEmployees } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchTemplates(val)
    }, { deep: true })

    watch(templateModalShow, val => {
      if (val == false) {
        resetTemplate()
      }
    })

    // watch(currentTemplate, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchTemplates
    } = useAPI()

    const {
      submitValidatedTemplate,
      removeTemplate
    } = useTemplates()

    const resetTemplate = () => {
      currentTemplate.value = JSON.parse(JSON.stringify(store.getters['template/getEmptyTemplate']))
    }

    const editTemplateLocal = (template) => {
      store.dispatch('template/getTemplate', template.id)
          .then(response => {
            currentTemplate.value = response.data
            templateModalShow.value = true
          })
    }

    const duplicateTemplateLocal = (template) => {
      store.dispatch('template/getTemplate', template.id)
          .then(response => {
            currentTemplate.value = response.data
            delete currentTemplate.value.id
            currentTemplate.value._isCloned = true

            templateModalShow.value = true
          })
    }

    const removeTemplateLocal = (template) => {
      removeTemplate(template)
          .then(response => {

          })
    }

    const submitValidatedTemplateLocal = () => {
      submitValidatedTemplate(currentTemplate.value)
          .then(response => {
            resetTemplate()
            templateModalShow.value = false
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetTemplate()

    // console.log(clone(autocompleteEmployees.value))

    store.dispatch('moduleView/getModuleViews', {
      per_page: 5000
    })
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
          console.log(currentView)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,
      getUserData,

      // Data
      module,
      payload,
      isReady,

      currentTemplate,
      templateModalShow,

      // Computed
      templates,
      templatesContext,

      // Methods
      editTemplateLocal,
      duplicateTemplateLocal,
      removeTemplateLocal,
      submitValidatedTemplateLocal,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {
    downloadByDocument,
    deleteTemplateAlert (template) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theTemplate') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeTemplateLocal(template)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>