<template>
  <div>
    <b-modal
        id="modal-sm-1"
        :title="title"
        v-model="isOpenLocal"
        size="sm"
        scrollable
        :hide-footer="hideFooter"
        :ok-title="capitalize($t('validate'))"
        :cancel-title="capitalize($t('cancel'))"
        @hide="tryCloseTemplateModal"
        @hidden="handleCancel"
        @ok="handleOk"
    >
      <validation-observer
          ref="observerRef"
          #default="{handleSubmit}"
      >
        <b-form
            @keydown.enter.stop.prevent=""
            @submit.stop.prevent="handleSubmit(submitValidatedTemplateLocal)"
        >

          <!--      Name-->
          <b-row class="mb-2">
            <b-col cols="12">
              <input-field
                  name="name"
                  :model.sync="templateLocal.name"
                  :display-label="false"
                  rules="required"
                  class="mb-2"
              />
            </b-col>

            <!--    Share-->
            <b-col cols="12">
              <input-select2
                  :model.sync="templateLocal.shared"
                  :options="sharedOptions"
                  :clearable="false"
                  reduce="value"
                  name="shared"
                  :display-label="false"
                  :placeholder="capitalize($t('share'))"
              />
            </b-col>

          </b-row>

          <b-row>
<!--            <b-col-->
<!--                v-if="displayTypeSelect('email')"-->
<!--                :md="'id' in templateLocal && displayTypeSelect('email')?'12':'6'"-->
<!--            >-->
<!--              <radio-input-->
<!--                  :model.sync="templateLocal.type"-->
<!--                  input-value="email"-->
<!--                  :disabled="disableTypeSelect || true"-->
<!--                  class="bg-white"-->

<!--                  @change="changeType"-->
<!--              >-->
<!--                <template #content>-->
<!--                  <icon-->
<!--                      icon="envelope"-->
<!--                      size="2x"-->
<!--                  />-->
<!--                  <h6 class="text-h6">-->
<!--                    {{ capitalize($tc('email')) }}-->
<!--                  </h6>-->
<!--                </template>-->
<!--              </radio-input>-->
<!--            </b-col>-->

            <b-col
                v-if="displayTypeSelect('event')"
                :md="'id' in templateLocal && displayTypeSelect('event')?'12':'6'"
            >
              <radio-input
                  :model.sync="templateLocal.type"
                  :disabled="disableTypeSelect"
                  input-value="event"
                  class="bg-white"

                  @change="changeType"
              >
                <template #content>
                  <icon
                      icon="calendar-alt"
                      size="2x"
                  />
                  <h6 class="text-h6">
                    {{ capitalize($tc('event')) }}
                  </h6>
                </template>
              </radio-input>
            </b-col>

            <b-col
                v-if="displayTypeSelect('reminder')"
                :md="'id' in templateLocal && displayTypeSelect('reminder')?'12':'6'"
            >
              <radio-input
                  :model.sync="templateLocal.type"
                  :disabled="disableTypeSelect"
                  input-value="reminder"
                  class="bg-white"

                  @change="changeType"
              >
                <template #content>
                  <icon
                      icon="history"
                      size="2x"
                  />
                  <h6 class="text-h6">
                    {{ capitalize($tc('reminder')) }}
                  </h6>
                </template>
              </radio-input>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
                v-if="displayTypeSelect('email')"
                :md="'id' in templateLocal && displayTypeSelect('email')?'12':'6'"
            >
              <radio-input
                  :model.sync="templateLocal.type"
                  input-value="email"
                  :disabled="disableTypeSelect"
                  class="bg-white"

                  @change="changeType"
              >
                <template #content>
                  <icon
                      icon="envelope"
                      size="2x"
                  />
                  <h6 class="text-h6">
                    {{ capitalize($tc('email')) }}
                  </h6>
                </template>
              </radio-input>
            </b-col>
<!--            <b-col-->
<!--                v-if="displayTypeSelect('reminder')"-->
<!--                :md="'id' in templateLocal && displayTypeSelect('reminder')?'12':'6'"-->
<!--            >-->
<!--              <radio-input-->
<!--                  :model.sync="templateLocal.type"-->
<!--                  :disabled="disableTypeSelect"-->
<!--                  input-value="reminder"-->
<!--                  class="bg-white"-->

<!--                  @change="changeType"-->
<!--              >-->
<!--                <template #content>-->
<!--                  <icon-->
<!--                      icon="history"-->
<!--                      size="2x"-->
<!--                  />-->
<!--                  <h6 class="text-h6">-->
<!--                    {{ capitalize($tc('reminder')) }}-->
<!--                  </h6>-->
<!--                </template>-->
<!--              </radio-input>-->
<!--            </b-col>-->

            <!--        <b-col md="6">-->
            <!--        </b-col>-->
          </b-row>


          <button
              ref="submitTemplateFormRef"
              class="d-none"
          ></button>
        </b-form>
      </validation-observer>


    </b-modal>

    <!-- modal event-->
    <modal-event
        :event.sync="templateLocal.template"
        :title="eventModalTitle"
        :isOpen.sync="eventModalShow"
        :is-template="true"

        @submitValidatedEvent="submitValidatedTemplateLocal"
    />

    <!-- modal reminder-->
    <reminder-modal
        :reminder.sync="templateLocal.template"
        :title="reminderModalTitle"
        :isOpen.sync="reminderModalShow"
        :is-template="true"

        @submitValidatedReminder="submitValidatedTemplateLocal"
    />

    <!-- modal email-->
    <email-prompt
        :email="templateLocal.template"
        :title="emailModalTitle"
        :isOpen.sync="emailModalShow"
        :is-template="true"

        @submitValidated="submitValidatedTemplateLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted, onBeforeUnmount } from '@vue/composition-api'
import useAPI                                                 from '../../utils/useAPI'
import { capitalize }                                         from '../../utils/filter'
import RadioInput                                             from '../input/RadioInput.vue'
import store                                                  from '../../store'
import { clone }                                              from '../../utils/utils'
import ModalEvent                                             from './Event.vue'
import i18n                                                   from '../../libs/i18n'
import ReminderModal                                          from './ReminderModal.vue'
import EmailPrompt                                            from './EmailPrompt.vue'
import InputField                                             from '../input/Input.vue'
import InputSelect2                                           from '../input/Select2.vue'
import Event2Form                                             from '../form/event2/Event2Form.vue'
import { ValidationObserver }                                 from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    Event2Form,
    InputSelect2,
    EmailPrompt,
    ReminderModal,
    ModalEvent,
    RadioInput,
    InputField
  },
  props: {
    template: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // console.log(clone(props.template))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const templateLocal = ref(props.template)
    const submitTemplateFormRef = ref(null)
    const observerRef = ref(null)
    const sharedOptions = ref([
      {
        value: 'private',
        _display: capitalize(i18n.t('private')),
      },
      {
        value: 'public',
        _display: capitalize(i18n.t('public')),
      },
    ])

    const eventModalShow = ref(false)
    const eventModalTitle = ref(i18n.t('NewEvent'))
    const reminderModalShow = ref(false)
    const reminderModalTitle = ref(i18n.t('NewReminder'))
    const emailModalShow = ref(false)
    const emailModalTitle = ref(i18n.t('NewEmail'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    const disableTypeSelect = computed(() => {
      if (
          templateLocal.value.name == '' ||
          templateLocal.value.name == null
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(templateLocal, (val) => {
      emit('update:template', val)
    })

    watch(() => props.isOpen, val => {
      if (val == true) {
        changeType()
      }
    })

    watch(eventModalShow, val => {
      if (val == false && (!'id' in templateLocal.value)) {
        templateLocal.value.type = null
      }
    })
    watch(reminderModalShow, val => {
      if (val == false && (!'id' in templateLocal.value)) {
        templateLocal.value.type = null
      }
    })
    watch(emailModalShow, val => {
      if (val == false && (!'id' in templateLocal.value)) {
        templateLocal.value.type = null
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseTemplateModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitTemplateFormRef.value.click()
      }
    }

    const submitValidatedTemplateLocal = () => {

      if (templateLocal.value.type == 'event') {
        delete templateLocal.value.template.endDate
        delete templateLocal.value.template.startDate
      } else if (templateLocal.value.type == 'reminder') {
        delete templateLocal.value.template.completionDate
      } else if(templateLocal.value.type == 'email') {
        templateLocal.value.template.toRecipients.forEach((toRecipient, i) => {
          templateLocal.value.template.toRecipients[i].emailAddress.name = toRecipient.emailAddress.address._display
          templateLocal.value.template.toRecipients[i].emailAddress.address = toRecipient.emailAddress.address.email
        })
      }
      // console.log(clone(templateLocal.value))

      emit('submitValidated')

      closePrompts()
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedTemplateLocal()
      }
    }

    const handleCancel = () => {}

    const closePrompts = () => {
      eventModalShow.value = false
      reminderModalShow.value = false
      emailModalShow.value = false
    }

    const changeType = () => {
      if (!('id' in templateLocal.value) && !('_isCloned' in templateLocal.value)) {
        if (templateLocal.value.type != null) {
          templateLocal.value.template = null

          if (templateLocal.value.type == 'event') {
            templateLocal.value.template = clone(store.getters['event3/getEmptyEvent'])
            eventModalShow.value = true
          } else if (templateLocal.value.type == 'reminder') {
            templateLocal.value.template = clone(store.getters['reminder/getEmptyReminder'])
            reminderModalShow.value = true
          } else if (templateLocal.value.type == 'email') {
            templateLocal.value.template = clone(store.getters['email/getEmptyEmail'])
            emailModalShow.value = true
          }
        }
      } else {
        if (templateLocal.value.type == 'event') {
          eventModalShow.value = true
        } else if (templateLocal.value.type == 'reminder') {
          reminderModalShow.value = true
        } else if (templateLocal.value.type == 'email') {
          emailModalShow.value = true
        }
        delete templateLocal.value._isCloned
      }
    }

    const displayTypeSelect = type => {
      if ('id' in templateLocal.value) {
        if (type == templateLocal.value.type) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      templateLocal,
      submitTemplateFormRef,
      observerRef,
      sharedOptions,

      // currentEvent,
      eventModalShow,
      eventModalTitle,
      reminderModalShow,
      reminderModalTitle,
      emailModalShow,
      emailModalTitle,

      // Computed
      hideFooter,
      disableTypeSelect,

      // Methods
      tryCloseTemplateModal,
      submitValidatedTemplateLocal,
      handleOk,
      handleCancel,
      changeType,
      displayTypeSelect,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    template: function (val) {
      this.templateLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>