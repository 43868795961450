<template>
  <div>
    <div
        v-if="title == null"
        v-b-hover="handleHover"
        class="border rounded-lg p-2 text-center"
        :class="{'border-secondary': isHover && modelLocal != inputValue && !disabled, 'border-primary': modelLocal == inputValue, 'text-muted': disabled, 'cursor-pointer': !disabled}"

        @click="(!disabled?modelLocal=inputValue:false);(!disabled?$emit('change'):false)"
    >
      <slot name="content"/>

      <div class="d-flex mt-2">
        <b-form-radio
            v-model="modelLocal"
            name="some-radios"
            :value="inputValue"
            :disabled="disabled"
            class="mx-auto"
        ></b-form-radio>
      </div>
    </div>

    <b-form-radio
        v-else
        v-model="modelLocal"
        name="some-radios"
        :value="inputValue"
        :disabled="disabled"
    >{{ title }}
    </b-form-radio>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    model: {
      type: String
    },
    inputValue: {},
    title: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const modelLocal = computed({
      get () {
        return props.model
      }, set (val) {
        emit('update:model', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(modelLocal, val => {
      if (val == props.inputValue) {
        // console.log("change")
        emit('change')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = (isHovered) => {
      isHover.value = isHovered
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHover,

      // Computed
      modelLocal,

      // Methods
      handleHover,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>